<template>
    <div class="component-container" :class="cmsBlock.backgroundYoutubeStyle">
        <div class="youtube-block">
            <LazyYoutube :src="oembedObject.url" thumbnailQuality="maxres" maxWidth="100%" :showTitle="this.cmsBlock.showTitle"/> 
        </div>
    </div>
</template>

<script>
import { LazyYoutube } from 'vue-lazytube'

export default {
    name: 'YouTube',

    props: [
        'cmsBlock'
    ],

    components: {
        LazyYoutube
    },

    computed: {
       oembedObject() {
           return JSON.parse(this.cmsBlock.oembedContent)
       } 
    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .youtube-block {
        max-width: $container-width; 
        height:unset; 
        aspect-ratio: 16 / 9;
        margin: 0 auto;

        @include breakpoint('tablet') {
            padding: 42px 40px;
        }
    }

}


</style>
